// modules
const Vue = require('vue');
const { DateTime } = require('luxon');

// components
const city = require('./components/city.js');
const cookies = require('./components/cookies.js');
const localtime = require('./components/local-time.js')(DateTime);
const temperature = require('./components/temperature.js');
const score = require('./components/score.js');
const visibility = require('./components/visibility.js');
const wind = require('./components/wind.js');

// dom elements
const dom = document.querySelector('.www');

// functions
const gtag = require('./functions/gtag.js');

(async () => {

	const metarDataFetchUrl = 'https://8y99vdczel.execute-api.eu-west-2.amazonaws.com/dev/metar/get';
	const airportsData = await fetch(metarDataFetchUrl).then(response => response.json());
	
	if (!airportsData) {
		console.error('Data not found');
		return;
	}

	const airportsDataOrdered = orderAirportsByAlgorithm(airportsData);
    const app = new Vue({
        el: '#app',
        data: {
			airports: airportsDataOrdered.slice(0, 10),
			distanceUnit: localStorage.distanceUnit || 'km',
			tempUnit: localStorage.tempUnit || 'C'
        },
        methods: {
			airportLiClass: (weatherInfo) => {
				try {
					if (weatherInfo.current.condition.code) {
						return `www-list-item www-list-item-weather-${weatherInfo.current.condition.code}`;
					}
				} catch (err) {
					return 'www-list-item';
				}
			},
            switchTemp: (unit) => {
                switch (unit) {
                    case 'c':
						localStorage.tempUnit = 'C';
						app.tempUnit = 'C'
                        break;
                    case 'f':
						localStorage.tempUnit = 'F';
						app.tempUnit = 'F'
                        break;
                    default:
                        break;
                }
			},
			switchDistance: (unit) => {
				switch (unit) {
                    case 'km':
						localStorage.distanceUnit = 'km';
						app.distanceUnit = 'km'
                        break;
                    case 'mi':
						localStorage.distanceUnit = 'mi';
						app.distanceUnit = 'mi'
                        break;
                    default:
                        break;
                }
			}
		},
		components: {
			city,
			cookies,
			'local-time': localtime,
			temperature,
			score,
			visibility,
			wind
		}
	});
	
	Vue.nextTick(function () {
		dom.style.display = 'block';
	});

    function orderAirportsByAlgorithm(airportsData) {
		return airportsData.filter(airportdata => {
			try {
				return !!airportdata.weatherInfo.location.country;
			} catch (error) {
				return false;
			}
		}).sort((a,b) => parseInt(b.score.points.total) - parseInt(a.score.points.total));
	}
	
	// GA
	window.dataLayer = window.dataLayer || [];
	gtag('js', new Date());
	gtag('config', 'UA-180160245-1');
})();